<template>
   <div :class="'m-p profile-container ' + ( this.$store.state.profileSideBar ? 'open' : '' )">
      <Documents />
   </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
name: 'My Commercial',
components: {
   Documents: defineAsyncComponent( () => import('@/components/Profile/Documents/Documents.vue') ),
},
}
</script>

<style scoped>
  .profile-container {
    height: 100vh;
  }
</style>
